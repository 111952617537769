import React from 'react'
import { SiLeetcode } from "react-icons/si";
import { FaLinkedin } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import "./landing.css"

function About() {
  return (
    <div id='about' className="text-gray-300 hero mt-20 xl:mt-0 xl:mb-0">
      <div className="hero-content flex-col lg:flex-row-reverse">
        <div className='text-left flex justify-center flex-col '>
          <div className='flex gap-6 flex-col'>
            <h2 className='text-3xl font-bold'> <span className='text-second'>1.</span> Sobre mí</h2>
            <div className='border shadow-xl bg-indigo-800 opacity-80 rounded-xl border-sky-500 flex flex-col h-[50%] xl:h-56 p-5 gap-2 '>
              <div className='flex justify-start flex-col'>
                <span className="text-lg">
                Mi nombre es <strong> Lucas Perata</strong>, soy programador y estudiante de la <strong>Universidad Abierta Interamericana</strong>. 
                Me apasiona crear proyectos que integren software y hardware para resolver problemas cotidianos de manera innovadora. 
                </span>
              </div>
              <div className='flex p-10 justify-around'>
                  <a href="https://github.com/lucas-perata" target="_blank" >                
                    <FaGithub className='transition ease-in-out hover:-translate-y-1 hover:scale-100 duration-200' size={40}/>
                  </a>
                  <a href="https://leetcode.com/u/lucas-perata/" target='_blank'>
                <SiLeetcode className='transition ease-in-out hover:-translate-y-1 hover:scale-100 duration-200' size={40} />
                </a>
                <a href="https://www.linkedin.com/in/lucas-perata-623902152/" target="_blank">
                <FaLinkedin className='transition ease-in-out hover:-translate-y-1 hover:scale-100 duration-200' size={40} />
                </a> 
              </div>
            </div>
          </div>
        </div>
      </div >
    </div >
  )
}

export default About
