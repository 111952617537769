import React from 'react'

function Footer() {
  return (
    <footer className="footer flex justify-end p-10 text-neutral-content items-center">
      <aside className="grid-flow-col items-center">
        <p>Lucas Perata © {new Date().getFullYear()} - Derechos reservados</p>
      </aside>
    </footer>
  )
}

export default Footer
